.uikit-table-1 {
	th {
		border: 0 !important;
		padding: 10px 20px;
	}
	td {
		border: 0 !important;
		padding: 12px 20px;
	}
	tbody {
		tr {
			border-top: 1px solid #d8d7d7;
		}
	}
}

.uikit-table-2 {
	background: var(--main-color) !important;
	th {
		border: 0 !important;
		padding: 10px 20px;
		color: #fff;
	}
	td {
		border: 0 !important;
		padding: 12px 20px;
		color: #fff;
	}
	tbody {
		tr {
			border-top: 1px solid #fff;
			color: #fff;
		}
	}
}

.uikit-table-3 {
	th {
		border: 0 !important;
		padding: 10px 20px;
	}
	td {
		border: 0 !important;
		padding: 12px 20px;
	}
	tbody {
		tr {
			border-top: 1px solid #d8d7d7;
			&:nth-child(1){
				background: #f3e9e9;
			}
			&:nth-child(3){
				background: #f3e9e9;
			}
			&:nth-child(5){
				background: #f3e9e9;
			}
			&:nth-child(7){
				background: #f3e9e9;
			}
		}
	}
}