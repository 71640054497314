/*
 * MODAL
 */

// .modal {
//     z-index: 1200;
// }

// .modal-top .modal-dialog {
//     margin-top: 0 !important;
// }

// .modal-header {
//     color: #2c304d;
//     border-bottom: 0.07rem solid #eee;
// }

// .modal-header .close {
//     font-size: 2rem;
// }

// .close {
//     color: #d2d1d5;
//     opacity: 0.8;
// }

// .modal-content {
//     border: 0 none;
// }

// .modal-backdrop.show {
//     opacity: 1;
// }

// .modal-backdrop {
//     background: rgba(46, 52, 81, 0.8);
// }

// .modal-dialog-centered .modal-content {
//     border-radius: 4px;
// }

// /*
//  * MODAL success
//  */

// .sa-icon {
//     width: 80px;
//     height: 80px;
//     border: 4px solid gray;
//     border-radius: 50%;
//     margin: 20px auto;
//     padding: 0;
//     position: relative;
//     box-sizing: content-box;
// }

// .sa-icon.sa-success .sa-line {
//     height: 5px;
//     background-color: #e76c90;
//     display: block;
//     border-radius: 2px;
//     position: absolute;
//     z-index: 2;
// }

// .sa-icon.sa-success .sa-line.sa-tip {
//     width: 25px;
//     left: 14px;
//     top: 46px;
//     -webkit-transform: rotate(45deg);
//     transform: rotate(45deg);
// }

// .sa-icon.sa-success .sa-line.sa-long {
//     width: 47px;
//     right: 8px;
//     top: 38px;
//     -webkit-transform: rotate(-45deg);
//     transform: rotate(-45deg);
// }

// .sa-icon.sa-success .sa-placeholder {
//     width: 80px;
//     height: 80px;
//     border: 4px solid rgba(228, 232, 240, 0.8);
//     -webkit-border-radius: 40px;
//     border-radius: 40px;
//     border-radius: 50%;
//     box-sizing: content-box;
//     position: absolute;
//     left: -4px;
//     top: -4px;
//     z-index: 2;
// }

// .sa-icon.sa-success .sa-fix {
//     width: 5px;
//     height: 90px;
//     background-color: #fff;
//     position: absolute;
//     left: 28px;
//     top: 8px;
//     z-index: 1;
//     -webkit-transform: rotate(-45deg);
//     transform: rotate(-45deg);
// }

// .sa-icon.sa-success {
//     border-color: #5d5386;
// }

// .sa-icon.sa-success {
//     border-color: transparent\9;
// }

// .sa-icon.sa-success .sa-line.sa-tip {
//     -ms-transform: rotate(45deg) \9;
// }

// .sa-icon.sa-success .sa-line.sa-long {
//     -ms-transform: rotate(-45deg) \9;
// }

// .sa-icon.sa-success:before,
// .sa-icon.sa-success:after {
//     content: '';
//     -webkit-border-radius: 40px;
//     border-radius: 40px;
//     border-radius: 50%;
//     position: absolute;
//     width: 60px;
//     height: 120px;
//     background: white;
//     -webkit-transform: rotate(45deg);
//     transform: rotate(45deg);
// }

// .sa-icon.sa-success::before {
//     -webkit-border-radius: 120px 0 0 120px;
//     border-radius: 120px 0 0 120px;
//     top: -7px;
//     left: -33px;
//     -webkit-transform: rotate(-45deg);
//     transform: rotate(-45deg);
//     -webkit-transform-origin: 60px 60px;
//     transform-origin: 60px 60px;
// }

// .sa-icon.sa-success:after {
//     -webkit-border-radius: 0 120px 120px 0;
//     border-radius: 0 120px 120px 0;
//     top: -11px;
//     left: 30px;
//     -webkit-transform: rotate(-45deg);
//     transform: rotate(-45deg);
//     -webkit-transform-origin: 0px 60px;
//     transform-origin: 0px 60px;
// }

// @-webkit-keyframes animateSuccessTip {
//     0% {
//         width: 0;
//         left: 1px;
//         top: 19px;
//     }
//     54% {
//         width: 0;
//         left: 1px;
//         top: 19px;
//     }
//     70% {
//         width: 50px;
//         left: -8px;
//         top: 37px;
//     }
//     84% {
//         width: 17px;
//         left: 21px;
//         top: 48px;
//     }
//     100% {
//         width: 25px;
//         left: 14px;
//         top: 45px;
//     }
// }

// @keyframes animateSuccessTip {
//     0% {
//         width: 0;
//         left: 1px;
//         top: 19px;
//     }
//     54% {
//         width: 0;
//         left: 1px;
//         top: 19px;
//     }
//     70% {
//         width: 50px;
//         left: -8px;
//         top: 37px;
//     }
//     84% {
//         width: 17px;
//         left: 21px;
//         top: 48px;
//     }
//     100% {
//         width: 25px;
//         left: 14px;
//         top: 45px;
//     }
// }

// @-webkit-keyframes animateSuccessLong {
//     0% {
//         width: 0;
//         right: 46px;
//         top: 54px;
//     }
//     65% {
//         width: 0;
//         right: 46px;
//         top: 54px;
//     }
//     84% {
//         width: 55px;
//         right: 0px;
//         top: 35px;
//     }
//     100% {
//         width: 47px;
//         right: 8px;
//         top: 38px;
//     }
// }

// @keyframes animateSuccessLong {
//     0% {
//         width: 0;
//         right: 46px;
//         top: 54px;
//     }
//     65% {
//         width: 0;
//         right: 46px;
//         top: 54px;
//     }
//     84% {
//         width: 55px;
//         right: 0px;
//         top: 35px;
//     }
//     100% {
//         width: 47px;
//         right: 8px;
//         top: 38px;
//     }
// }

// @-webkit-keyframes rotatePlaceholder {
//     0% {
//         transform: rotate(-45deg);
//         -webkit-transform: rotate(-45deg);
//     }
//     5% {
//         transform: rotate(-45deg);
//         -webkit-transform: rotate(-45deg);
//     }
//     12% {
//         transform: rotate(-405deg);
//         -webkit-transform: rotate(-405deg);
//     }
//     100% {
//         transform: rotate(-405deg);
//         -webkit-transform: rotate(-405deg);
//     }
// }

// @keyframes rotatePlaceholder {
//     0% {
//         transform: rotate(-45deg);
//         -webkit-transform: rotate(-45deg);
//     }
//     5% {
//         transform: rotate(-45deg);
//         -webkit-transform: rotate(-45deg);
//     }
//     12% {
//         transform: rotate(-405deg);
//         -webkit-transform: rotate(-405deg);
//     }
//     100% {
//         transform: rotate(-405deg);
//         -webkit-transform: rotate(-405deg);
//     }
// }

// .animateSuccessTip {
//     -webkit-animation: animateSuccessTip 1s;
//     animation: animateSuccessTip 1s;
// }

// .animateSuccessLong {
//     -webkit-animation: animateSuccessLong 1.4s;
//     animation: animateSuccessLong 1.4s;
// }

// .sa-icon.sa-success.animate::after {
//     -webkit-animation: rotatePlaceholder 8s ease-in;
//     animation: rotatePlaceholder 8s ease-in;
// }

.model {
  .model-top,
  .model-centered,
  .model-input {
    .header-text {
      margin-left: 20px;
      h3 {
        font-size: 20px;
      }
    }
    .model-inner {
      padding: 20px 30px;
      background: #fff;
      margin: 0 20px;
      border-radius: 5px;
    }
  }
}
