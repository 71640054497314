.uikit-badge-inner {
  padding: 15px !important;
  border-radius: 4px;
  li {
    display: inline-flex;
    font-weight: 600;
    position: relative;
    border-bottom: 1px solid #d5d5d5;
    padding-bottom: 10px;
    margin-bottom: 10px;
    img {
      margin-right: 8px;
      height: 30px;
      width: 30px;
      border-radius: 50%;
    }
    .count {
      height: 25px;
      width: 25px;
      line-height: 25px;
      border-radius: 50%;
      display: inline-block;
      text-align: center;
      color: #fff;
      position: absolute;
      right: 0;
      top: 3px;
      font-size: 14px;
      &.text {
        color: var(--heading-color);
      }
    }

    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
}

.rtl .count {
  left: 0;
}

.single-checkbox-inner {
  font-weight: 600;
  color: var(--heading-color);
  label {
    color: var(--heading-color) !important;
  }
}
