
//== Template Main Color
$color-main: #11A44C;
$white: #fff;
$black: #15131C;


/*----------------------------------------------
    # Typography
----------------------------------------------*/
@import url("https://fonts.googleapis.com/css?family=Public+Sans:300,400,400i,500,500i,600,600i,700,700&display=swap");

//  transition
$transition: all .3s ease-in;
$transition-long: all .5s ease-in;
