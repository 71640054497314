.change-mode {
  text-align: right;
}
.change-mode .custom-switch.custom-switch-icon .custom-control-input {
  height: 100%;
  left: 0;
  z-index: -1;
  width: 1rem;
  opacity: 0;
}
.change-mode .custom-switch.custom-switch-icon label.custom-control-label {
  height: 30px;
  cursor: pointer;
  margin-bottom: 0;
}
.custom-switch.custom-switch-icon .custom-control-label {
  width: 42px;
  padding: 0;
  position: relative;
  margin-right: 29px;
}
.custom-control-label:after,
.custom-control-label:before {
  position: absolute;
  top: 11.5rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
}
.custom-switch.custom-switch-icon .custom-control-label:before {
  height: 20px;
  transition: all 0.3s ease-in-out;
  user-select: none;
  top: 0;
  left: 1px;
  width: 2.75rem;
}
.change-mode
  .custom-switch.custom-switch-icon
  label.custom-control-label:before {
  width: 70px;
  height: 30px;
  top: 0;
  left: 0;
  background-color: #f7f9fa;
  border-color: var(--main-color);
  border-radius: 8px;
}
.change-mode
  .custom-switch.custom-switch-icon
  .custom-control-input:checked
  ~ .custom-control-label:before {
  background-color: #181818;
  border-color: #181818;
}
.custom-switch.custom-switch-icon .custom-control-label:after {
  position: absolute;
  top: 1px;
  left: 2px;
  transition: all 0.3s ease-in-out;
  user-select: none;
  height: 18px;
  width: 18px;
}
.change-mode
  .custom-switch.custom-switch-icon
  label.custom-control-label:after {
  top: 0;
  left: 0;
  width: 35px;
  height: 30px;
  border-radius: 5px 0 0 5px;
  background-color: var(--main-color);
  border-color: var(--main-color);
  z-index: 0;
}
.custom-switch.custom-switch-icon
  .custom-control-input:checked
  ~ .custom-control-label:after {
  transform: translateX(1.4rem);
}
.dark
  .change-mode
  .custom-switch.custom-switch-icon
  label.custom-control-label:after {
  border-radius: 0 5px 5px 0;
  left: 36px;
}
.dark .custom-switch.custom-switch-icon label .switch-icon-left {
  cursor: pointer;
  color: #333;
}
.change-mode
  .custom-switch.custom-switch-icon
  .custom-control-input:checked
  ~ .custom-control-label:after {
  transform: translateX(35px);
  border-radius: 0 5px 5px 0px;
}
.custom-switch.custom-switch-icon label .switch-icon-left {
  position: absolute;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  top: -3px;
  left: 8px;
  right: auto;
  color: #fff;
}
.custom-switch.custom-switch-icon label .switch-icon-left {
  position: absolute;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  top: -3px;
  left: 8px;
  right: auto;
  color: #fff;
}
.change-mode
  .custom-switch.custom-switch-icon
  label.custom-control-label
  .switch-icon-left {
  top: 0;
  z-index: 1;
  left: 0;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
}
.change-mode
  .custom-switch.custom-switch-icon
  label.custom-control-label
  .switch-icon-left
  i {
  font-size: 12px;
}
.custom-switch.custom-switch-icon label .switch-icon-right {
  position: absolute;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  top: -3px;
  right: 5px;
  left: auto;
}
.change-mode
  .custom-switch.custom-switch-icon
  label.custom-control-label
  .switch-icon-right {
  color: #333;
  top: 0;
  z-index: 9;
  right: -28px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
}
.dark
  .change-mode
  .custom-switch.custom-switch-icon
  label.custom-control-label
  .switch-icon-right {
  z-index: 9;
  color: #fff;
}
.change-mode
  .custom-switch.custom-switch-icon
  label.custom-control-label
  .switch-icon-right
  i {
  font-size: 12px;
}
.dark {
  background: #181818 !important;
  .balance-area {
    p,
    h2 {
      color: #fff;
    }
  }
  .ba-single-history {
    box-shadow: none;
  }
  .signin-area-inner h3,
  .signin-area-inner p,
  .contact-form-inner p,
  .btn-content-text,
  .verify-form-inner p,
  .balance-title h6,
  .ba-navbar-user .details h5,
  .ba-navbar-user .details p,
  .ba-navbar .ba-main-menu h5,
  .ba-navbar .ba-main-menu ul li a,
  .modal-title,
  label,
  .section-title .title {
    color: #fff;
  }
  .dark-area {
    .section-title .title {
      color: var(--heading-color);
    }
  }
  .add-balance-inner-wrap,
  .ba-navbar {
    background: #181818;
  }
  .modal-content,
  .ba-navbar .ba-navbar-user {
    background: #222;
  }
  .ba-navbar .ba-main-menu ul li a:hover {
    color: #7c00cf;
  }
  .blog-details-inner h6,
  .blog-details-inner p,
  .footer-top p {
    color: #fff;
  }
  .dark-logo {
    display: block;
  }
  .main-logo {
    display: none;
  }
}
