/*---------------------------------------------------
    Footer style
----------------------------------------------------*/

.footer-area {
  margin-bottom: 60px;
}
.footer-top {
  padding: 40px 0;
  // background-color: #eee;
  .footer-logo {
    margin-bottom: 15px;
    display: inline-block;
  }
  p {
    margin-bottom: 18px;
    margin-top: 12px;
  }
  .social-area {
    margin-bottom: 20px;
  }
}
.footer-bottom {
  position: fixed;
  width: 100%;
  z-index: 9;
  bottom: 0;
  left: 0;
  padding: 20px 0;
  &:after {
    position: absolute;
    content: "";
    background: #fff;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    border: 1px solid #f7f9fa;
    border-radius: 10px;
  }
  ul {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      display: inline-block;
      margin: 0 18px;
      p {
        margin-bottom: 0;
        font-size: 10px;
        transition: 0.4s;
      }
      a {
        transition: 0.4s;
        &:hover {
          color: $color-main;
          p {
            color: $color-main;
          }
        }
      }
      .ba-add-balance-btn {
        height: 60px;
        width: 60px;
        border-radius: 50%;
        display: inline-block;
        background: #fff;
        line-height: 58px;
        border: 1px solid #f1f1f1;
        padding: 0;
        margin-bottom: 0;
        box-shadow: 0px 0px 15px #f1f4f5;
        position: absolute;
        top: -29px;
        left: 50%;
        transform: translateX(-50%);
        img {
          margin-bottom: 0;
        }
      }
      &:nth-child(2) {
        margin-right: 40px;
      }
      &:nth-child(3) {
        margin-left: 40px;
      }
    }
  }
}

.dark .footer-top {
  background-color: #181818;
}
