@media (max-width: 576px) {
  .container-fluid {
    padding: 15px;
  }
  .content-inner {
    margin-left: 0 !important;
  }
  .page-header,
  .page-header-2 {
    padding: 20px 15px;
  }
}

.has-shadow {
  box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.08);
}

.widget {
  background: #fff;
  border-radius: 0;
  border: none;
  margin-bottom: 30px;
}

.widget-header {
  padding: 0.85rem 0;
  position: relative;
  width: 100%;
  h4 {
    margin-bottom: 0;
  }
}

.widget-header.bordered {
  border-bottom: 0.07rem solid #eee;
}

.widget-body {
  padding: 1.4rem;
}

.btn-c {
  font-weight: 600 !important;
  line-height: 1.25;
  border: 2px solid transparent;
  padding: 10px 20px;
  font-size: 0.85rem;
  border-radius: 60px;
  transition: all 0.2s ease-in-out;
  position: relative;
  overflow: hidden;
  margin: 2px;
}

.btn-group,
.btn-group-vertical {
  display: inline-block;
}
.ripple {
  background-position: center;
  transition: background 0.6s;
}

.ripple:hover {
  background: rgba(255, 255, 255, 0.15)
    radial-gradient(circle, transparent 1%, rgba(255, 255, 255, 0.15) 1%)
    center/15000%;
}

.ripple:active {
  background-color: rgba(255, 255, 255, 0.15);
  background-size: 100%;
  transition: background 0s;
}

.btn-c:focus,
.btn-c.focus {
  outline: none !important;
  box-shadow: none !important;
}

.btn-c:active,
.btn-c.active {
  background-image: none;
}

a.btn-c.disabled,
fieldset[disabled] a.btn-c {
  pointer-events: none;
}

.dropdown-menu {
  border-color: #eee;
  box-shadow: 1px 1px 30px rgba(0, 0, 0, 0.15);
}

.btn-shadow,
.btn-shadow a {
  color: #5d5386;
  background-color: #fff;
  border-color: #fff;
  box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.15);
}

.btn-shadow i,
.btn-shadow a i {
  font-size: 1.2rem;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.4rem;
}

.btn-shadow:hover,
.btn-shadow a:hover {
  color: #5d5386;
  box-shadow: 0 1px 20px 1px rgba(52, 40, 104, 0.2);
}

.btn-shadow:active {
  color: #5d5386;
  background-color: #fff;
  border-color: #fff;
}

.btn-shadow:focus,
.btn-shadow.focus {
  box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.15) !important;
}

.btn-shadow.disabled,
.btn-shadow:disabled {
  color: #9c90d0;
  background-color: #fff;
  border-color: #fff;
}

.btn-shadow:active,
.btn-shadow.active,
.show > .btn-shadow.dropdown-toggle {
  color: #5d5386;
  background-color: #fff;
  background-image: none;
}

.btn-gradient-01,
.btn-gradient-01 a {
  background: #e23f83;
  background: linear-gradient(to right, #e23f83 0%, #ea5844 100%);
  background-size: 200% auto;
  font-weight: 600;
  transition: 0.5s;
  color: #fff;
  border: 0 none;
  padding: 12px 20px;
}

.btn-gradient-01 i,
.btn-gradient-01 a i {
  color: #fff;
  display: inline-block;
  font-size: 1.2rem;
  vertical-align: middle;
  margin-right: 0.4rem;
}

.btn-gradient-01:hover,
.btn-gradient-01 a:hover {
  color: #fff;
  background-position: right center;
}

.btn-gradient-01:active {
  color: #fff;
  background-color: #e23f83;
  border-color: #e23f83;
}

.btn-gradient-01:focus,
.btn-gradient-01.focus {
  color: #fff;
  box-shadow: none;
}

.btn-gradient-01.disabled,
.btn-gradient-01:disabled {
  background-color: none;
  opacity: 0.3;
}

.btn-gradient-01:active,
.btn-gradient-01.active,
.show > .btn-gradient-01.dropdown-toggle {
  color: #fff;
  background-color: #e23f83;
  background-image: none;
}

.btn-gradient-02,
.btn-gradient-02 a {
  background: #5d5386;
  background: linear-gradient(to right, #5d5386 0%, #5d5386 100%);
  background-size: 200% auto;
  font-weight: 600;
  transition: 0.5s;
  color: #fff;
  border: 0 none;
  padding: 12px 20px;
}

.btn-gradient-02 i,
.btn-gradient-02 a i {
  display: inline-block;
  vertical-align: middle;
  font-size: 1.2rem;
  margin-right: 0.4rem;
}

.btn-gradient-02:hover,
.btn-gradient-02 a:hover {
  color: #fff;
  background-position: right center;
}

.btn-gradient-02:active {
  color: #fff;
  background-color: #5d5386;
  border-color: #5d5386;
}

.btn-gradient-02:focus,
.btn-gradient-02.focus {
  color: #fff;
  box-shadow: none;
}

.btn-gradient-02.disabled,
.btn-gradient-02:disabled {
  background-color: none;
  opacity: 0.3;
}

.btn-gradient-02:active,
.btn-gradient-02.active,
.show > .btn-gradient-02.dropdown-toggle {
  color: #fff;
  background-color: #5d5386;
  background-image: none;
}

.btn-gradient-03,
.btn-gradient-03 a {
  background: #6859ea;
  background: linear-gradient(to right, #6859ea 0%, #6acbe0 100%);
  background-size: 200% auto;
  font-weight: 600;
  transition: 0.5s;
  color: #fff;
  border: 0 none;
  padding: 12px 20px;
}

.btn-gradient-03 i,
.btn-gradient-03 a i {
  display: inline-block;
  font-size: 1.2rem;
  vertical-align: middle;
  margin-right: 0.4rem;
}

.btn-gradient-03:hover,
.btn-gradient-03 a:hover {
  color: #fff;
  background-position: right center;
}

.btn-gradient-03:active {
  color: #fff;
  background-color: #e23f83;
  border-color: #e23f83;
}

.btn-gradient-03:focus,
.btn-gradient-03.focus {
  color: #fff;
  box-shadow: none;
}

.btn-gradient-03.disabled,
.btn-gradient-03:disabled {
  background-color: none;
  opacity: 0.3;
}

.btn-gradient-03:active,
.btn-gradient-03.active,
.show > .btn-gradient-03.dropdown-toggle {
  color: #fff;
  background-color: #e23f83;
  background-image: none;
}

.btn-gradient-04,
.btn-gradient-04 a {
  background: #41c7af;
  background: linear-gradient(to right, #41c7af 0%, #54e38e 100%);
  background-size: 200% auto;
  font-weight: 600;
  transition: 0.5s;
  color: #fff;
  border: 0 none;
  padding: 12px 20px;
}

.btn-gradient-04 i,
.btn-gradient-04 a i {
  display: inline-block;
  font-size: 1.2rem;
  vertical-align: middle;
  margin-right: 0.4rem;
}

.btn-gradient-04:hover,
.btn-gradient-04 a:hover {
  color: #fff;
  background-position: right center;
}

.btn-gradient-04:active {
  color: #fff;
  background-color: #e23f83;
  border-color: #e23f83;
}

.btn-gradient-04:focus,
.btn-gradient-04.focus {
  color: #fff;
  box-shadow: none;
}

.btn-gradient-04.disabled,
.btn-gradient-04:disabled {
  background-color: none;
  opacity: 0.3;
}

.btn-gradient-04:active,
.btn-gradient-04.active,
.show > .btn-gradient-04.dropdown-toggle {
  color: #fff;
  background-color: #e23f83;
  background-image: none;
}

.btn-gradient-05,
.btn-gradient-05 a {
  background: #bc0056;
  background: linear-gradient(to right, #bc0056 0%, #730062 100%);
  background-size: 200% auto;
  font-weight: 600;
  transition: 0.5s;
  color: #fff;
  border: 0 none;
  padding: 12px 20px;
}

.btn-gradient-05 i,
.btn-gradient-05 a i {
  display: inline-block;
  font-size: 1.2rem;
  vertical-align: middle;
  margin-right: 0.4rem;
}

.btn-gradient-05:hover,
.btn-gradient-05 a:hover {
  color: #fff;
  background-position: right center;
}

.btn-gradient-05:active {
  color: #fff;
  background-color: #e23f83;
  border-color: #e23f83;
}

.btn-gradient-05:focus,
.btn-gradient-05.focus {
  color: #fff;
  box-shadow: none;
}

.btn-gradient-05.disabled,
.btn-gradient-05:disabled {
  background-color: none;
  opacity: 0.3;
}

.btn-gradient-05:active,
.btn-gradient-05.active,
.show > .btn-gradient-05.dropdown-toggle {
  color: #fff;
  background-color: #e23f83;
  background-image: none;
}

.btn-primary {
  color: #fff;
  background-color: #5d5386;
  border-color: #5d5386;
}

.btn-primary i {
  color: #fff;
  font-size: 1.2rem;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.4rem;
}

.btn-primary:hover {
  color: #fff;
  background-color: #5d5386;
  border-color: #5d5386;
}

.btn-primary:active {
  color: #fff;
  background-color: #5d5386;
  border-color: #5d5386;
}

.btn-primary:focus,
.btn-primary.focus {
  box-shadow: none;
  outline: none;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #9086b9;
  border-color: #9086b9;
}

.btn-primary:active,
.btn-primary.active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #594f82;
  border-color: #5d5386;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #5d5386;
  border-color: #5d5386;
}

.btn-secondary {
  color: #2c304d;
  background-color: #e4e8f0;
  border: 2px solid #e4e8f0;
}

.btn-secondary i {
  font-size: 1.2rem;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.4rem;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #2c304d;
  border-color: #2c304d;
}

.btn-secondary:focus,
.btn-secondary.focus {
  border-color: transparent;
  box-shadow: none;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #9e9ead;
  background-color: #edf0f5;
  border-color: #edf0f5;
}

.btn-secondary:active,
.btn-secondary.active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #2c304d;
  background-image: none;
}

.btn-info {
  color: #fff;
  background-color: #5bc0de;
}

.btn-info i {
  color: #fff;
  font-size: 1.2rem;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.4rem;
}

.btn-info:hover {
  color: #fff;
  background-color: #31b0d5;
  border-color: #31b0d5;
}

.btn-info:focus,
.btn-info.focus {
  box-shadow: 0 0 0 2px rgba(91, 192, 222, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
  background-color: #5bc0de;
}

.btn-info:active,
.btn-info.active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #31b0d5;
  border-color: #31b0d5;
  background-image: none;
}

.btn-success {
  color: #fff;
  background-color: #5cb85c;
}

.btn-success i {
  color: #fff;
  font-size: 1.2rem;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.4rem;
}

.btn-success:hover {
  color: #fff;
  background-color: #449d44;
  border-color: #449d44;
}

.btn-success:focus,
.btn-success.focus {
  box-shadow: 0 0 0 2px rgba(92, 184, 92, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
  background-color: #5cb85c;
}

.btn-success:active,
.btn-success.active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #449d44;
  border-color: #449d44;
  background-image: none;
}

.btn-warning {
  color: #fff;
  background-color: #f0ad4e;
}

.btn-warning i {
  color: #fff;
  font-size: 1.2rem;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.4rem;
}

.btn-warning:hover {
  color: #fff;
  background-color: #ec971f;
  border-color: #ec971f;
}

.btn-warning:focus,
.btn-warning.focus {
  box-shadow: 0 0 0 2px rgba(240, 173, 78, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
  background-color: #f0ad4e;
}

.btn-warning:active,
.btn-warning.active,
.show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #ec971f;
  border-color: #ec971f;
  background-image: none;
}

.btn-danger {
  color: #fff;
  background-color: #fe195e;
}

.btn-danger i {
  color: #fff;
  font-size: 1.2rem;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.4rem;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c9302c;
  border-color: #c9302c;
}

.btn-danger:focus,
.btn-danger.focus {
  box-shadow: 0 0 0 2px rgba(217, 83, 79, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
  background-color: #fe195e;
}

.btn-danger:active,
.btn-danger.active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #c9302c;
  border-color: #c9302c;
  background-image: none;
}

.btn-light {
  color: #2c304d;
  background-color: #f8f9fa;
}

.btn-light i {
  font-size: 1.2rem;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.4rem;
}

.btn-light:hover {
  color: #2c304d;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:focus,
.btn-light.focus {
  box-shadow: 0 0 0 2px rgba(217, 83, 79, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
  background-color: #fe195e;
}

.btn-light:active,
.btn-light.active,
.show > .btn-light.dropdown-toggle {
  color: #2c304d;
  background-color: #f8f8f8;
  border-color: #f8f8f8;
  background-image: none;
}

.btn-dark {
  color: #fff;
  background-color: #2c304d;
}

.btn-dark i {
  font-size: 1.2rem;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.4rem;
}

.btn-dark:hover {
  color: #fff;
  background-color: #2c304d;
  border-color: #2c304d;
}

.btn-dark:focus,
.btn-dark.focus {
  box-shadow: 0 0 0 2px rgba(217, 83, 79, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
  background-color: #fe195e;
}

.btn-dark:active,
.btn-dark.active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #2e324f;
  border-color: #2e324f;
  background-image: none;
}

.btn-outline-primary {
  color: #5d5386;
  border-color: #5d5386;
  background-image: none;
  background-color: transparent;
}

.btn-outline-primary i {
  font-size: 1.2rem;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.4rem;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #5d5386;
  border-color: #5d5386;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 2px rgba(121, 106, 238, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #796aee;
  background-color: transparent;
}

.btn-outline-primary:active,
.btn-outline-primary.active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #5d5386;
  border-color: #5d5386;
}

.btn-outline-secondary {
  color: #2c304d;
  border-color: #e4e8f0;
  background-image: none;
  background-color: transparent;
}

.btn-outline-secondary i {
  font-size: 1.2rem;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.4rem;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #2c304d;
  border-color: #2c304d;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: 0 0 0 2px rgba(204, 204, 204, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #afaeae;
  background-color: transparent;
}

.btn-outline-secondary:active,
.btn-outline-secondary.active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #2c304d;
  border-color: #2c304d;
}

.btn-outline-info {
  color: #5bc0de;
  border-color: #5bc0de;
  background-image: none;
  background-color: transparent;
}

.btn-outline-info i {
  font-size: 1.2rem;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.4rem;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #5bc0de;
  border-color: #5bc0de;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: 0 0 0 2px rgba(91, 192, 222, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #5bc0de;
  background-color: transparent;
}

.btn-outline-info:active,
.btn-outline-info.active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #5bc0de;
}

.btn-outline-success {
  color: #5cb85c;
  border-color: #5cb85c;
  background-image: none;
  background-color: transparent;
}

.btn-outline-success i {
  font-size: 1.2rem;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.4rem;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #5cb85c;
  border-color: #5cb85c;
}

.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: 0 0 0 2px rgba(92, 184, 92, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #5cb85c;
  background-color: transparent;
}

.btn-outline-success:active,
.btn-outline-success.active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #5cb85c;
}

.btn-outline-warning {
  color: #f0ad4e;
  border-color: #f0ad4e;
  background-image: none;
  background-color: transparent;
}

.btn-outline-warning i {
  font-size: 1.2rem;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.4rem;
}

.btn-outline-warning:hover {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #f0ad4e;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: 0 0 0 2px rgba(240, 173, 78, 0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #f0ad4e;
  background-color: transparent;
}

.btn-outline-warning:active,
.btn-outline-warning.active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #fff;
  background-color: #f0ad4e;
}

.btn-outline-danger {
  color: #fe195e;
  border-color: #fe195e;
  background-image: none;
  background-color: transparent;
}

.btn-outline-danger i {
  font-size: 1.2rem;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.4rem;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #fe195e;
  border-color: #fe195e;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: 0 0 0 2px rgba(217, 83, 79, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #fe195e;
  background-color: transparent;
}

.btn-outline-danger:active,
.btn-outline-danger.active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #fe195e;
}

.btn-outline-light {
  color: #2c304d;
  border-color: #f3f4f5;
  background-image: none;
  background-color: transparent;
}

.btn-outline-light i {
  font-size: 1.2rem;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.4rem;
}

.btn-outline-light:hover {
  color: #2c304d;
  background-color: #f3f4f5;
  border-color: #f3f4f5;
}

.btn-outline-light:focus,
.btn-outline-light.focus {
  box-shadow: 0 0 0 2px rgba(217, 83, 79, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f3f4f5;
  background-color: transparent;
}

.btn-outline-light:active,
.btn-outline-light.active,
.show > .btn-outline-light.dropdown-toggle {
  color: #2c304d;
  background-color: #f3f4f5;
}

.btn-outline-dark {
  color: #2c304d;
  border-color: #2c304d;
  background-image: none;
  background-color: transparent;
}

.btn-outline-dark i {
  font-size: 1.2rem;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.4rem;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #2c304d;
  border-color: #2c304d;
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
  box-shadow: 0 0 0 2px rgba(217, 83, 79, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #2c304d;
  background-color: transparent;
}

.btn-outline-dark:active,
.btn-outline-dark.active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #2c304d;
}

.btn-lg {
  padding: 0.75rem 2.5rem;
  font-size: 1rem;
  border-radius: 4.2rem;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.85rem;
}

.btn-square {
  border-radius: 0;
}

.btn-circle {
  border-radius: 50%;
  padding: 10px;
  text-align: center;
}

.btn-circle i {
  font-size: 1.6rem;
}

.button-nav {
  font-weight: 500;
}

.button-nav.nav-tabs {
  border: 0 none;
}

.button-nav.nav-tabs > li > a::after {
  display: none;
}

.button-nav * {
  transition: all 0.35s ease;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.button-nav li {
  display: inline-block;
  list-style: outside none none;
  margin: 0 0.5rem;
  padding: 0;
}

.button-nav li:first-child {
  margin-left: 0;
}

.button-nav li:last-child {
  margin-right: 0;
}

.button-nav a {
  padding: 0.5rem 0;
  color: #2c304d;
  text-decoration: none;
  display: inline-block;
}

.button-nav a.active {
  background: #5d5386;
  padding: 0.5rem 1.07rem;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  border-radius: 35px;
}

.button-nav a.active:hover {
  color: #fff !important;
}

.button-nav a:hover {
  background: #5d5386 !important;
  padding: 0.5rem 1.07rem;
  text-decoration: none;
  display: inline-block;
  border-radius: 35px;
}

.button-nav.nav-tabs > li > a:hover {
  color: #fff !important;
}

.button-nav a:before {
  bottom: 0;
  left: 0;
}

.rounded-button {
  display: inline-block;
  width: 45px;
  height: 45px;
  line-height: 45px;
  font-size: 1.4rem;
  text-align: center;
  border-radius: 50%;
}

a.rounded-button {
  color: #fff;
}

a:hover.rounded-button {
  opacity: 0.8;
}
