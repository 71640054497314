/*---------------------------------------
    ## Back Top
---------------------------------------*/
.back-to-top {
  position: fixed;
  right: 30px;
  bottom: 30px;
  width: 50px;
  height: 50px;
  color: $white;
  background-color: $color-main;
  text-align: center;
  line-height: 60px;
  border-radius: 50%;
  z-index: 99;
  font-size: 30px;
  cursor: pointer;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  display: none;
  i {
    animation: scrolltop 2s infinite;
  }
}

@keyframes scrolltop {
  from {
    transform: translate3d(0, 0px, 0);
    filter: alpha(opacity=0);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  10% {
    transform: translate3d(0, -3px, 0);
    filter: alpha(opacity=100);
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }
  to {
    transform: translate3d(0, -15px, 0);
    filter: alpha(opacity=0);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}

/*-------------------------
    ## Common Padding Css
---------------------------*/

.pd-top-36 {
  padding-top: 36px;
}

.pd-top-40 {
  padding-top: 40px;
}
.pd-top-110 {
  padding-top: 110px;
}

.pd-bottom-35 {
  padding-bottom: 35px;
}

.pd-bottom-40 {
  padding-bottom: 40px;
}

.mg-top-30 {
  margin-top: 30px;
}

.mg-top-50 {
  margin-top: 51px;
}

.mg-top-35 {
  margin-top: 35px;
}

.mg-top-40 {
  margin-top: 40px;
}

.mg-top-90 {
  margin-top: 100px;
}
.mg-top-110 {
  margin-top: 110px;
}
.mg-top-120 {
  margin-top: 120px;
}

.mg-bottom-35 {
  margin-bottom: 35px;
}

.mg-bottom-40 {
  margin-bottom: 40px;
}

.bg-base {
  background: var(--main-color);
}
.bg-black {
  background: var(--heading-color);
}
.bg-purple {
  background: #6a0dad;
}
.bg-pink {
  background: #ff1493;
}
.bg-yellow-2 {
  background: #f19717;
}
.bg-yellow-2 {
  background: #0000ff;
}

/*---------------------------------------
    ## Button
---------------------------------------*/

.btn {
  border: 0;
  height: 50px;
  line-height: 50px;
  font-size: 14px;
  padding: 0 25px;
  transition: $transition;
  border-radius: 50px;

  i {
    padding-left: 10px;
  }
}

.btn-large {
  border: 0;
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  padding: 0 25px;
  transition: $transition;
  border-radius: 50px;
  font-weight: 500;
  display: inline-block;
  text-align: center;
  &:hover {
    color: $white;
    opacity: 0.7;
  }
  &:focus {
    box-shadow: none;
    color: $white;
    outline: 0;
    opacity: 0.7;
  }
  &:active {
    box-shadow: none;
    color: $white;
    outline: 0;
    opacity: 0.7;
  }
  i {
    padding-left: 10px;
  }
}

.btn-gray {
  color: $white;
  background: #dadada;
}

.btn-blue {
  color: $white;
  background: #11a44c;
}

.btn-red {
  background: #f9376c;
  color: $white;
}

.btn-purple {
  background: #7c00cf;
  color: $white;
}

.btn-white {
  background: $white;
  color: #15131c;
}

.btn-white-border {
  border: 1px solid $white !important;
  color: #fff;
  &:hover {
    background: #fff;
    color: var(--main-color);
  }
}

.btn-green {
  background: #1dcc70;
  color: $white;
}

.readmore-btn {
  color: $color-main;
  font-weight: 600;
  font-size: 16px;
  transition: 0.4s;
}

.btn-content-text {
  font-size: 14px;
  font-weight: 500;
  color: #4b4b4b;
  a {
    color: var(--main-color);
  }
}

.btn-success {
  color: #fff;
  background-color: #11a44c !important;
  border-color: #11a44c !important;
}

/*-------------------------
    ## Background
---------------------------*/

.bg-gray {
  background: #f2f2f2;
}

.bg-user-setting:after {
  background-image: url("~/public/Assets/Images/bg/9.png") !important;
  background-size: cover !important;
  background-position: center !important;
  border-radius: 5px;
}

.bg-transaction-details::after {
  background-image: url("~/public/Assets/Images/bg/8.png") !important;
  background-size: cover !important;
  background-position: center !important;
  border-radius: 5px;
}

/*-------------------------
    ## section-title
---------------------------*/
.section-title {
  margin-bottom: 14px;
  overflow: hidden;
  .title {
    font-size: 24px;
    font-weight: 600;
    float: left;
    display: inline-block;
  }
  .subtitle {
    font-size: 12px;
  }
  a {
    float: right;
    padding-top: 7px;
    color: #11a44c;
    font-weight: 500;
    svg {
      font-size: 20px;
    }
    &.sub-delete {
      color: #ff396f;
    }
  }
  &.style-two {
    .title {
      color: $white;
    }
    a {
      color: $white;
    }
  }
  &.style-three {
    .title {
      float: none;
    }
  }
  .count-title {
    color: #fff;
    background: #1dcc70;
    height: 28px;
    width: 28px;
    line-height: 28px;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    padding-top: 0;
    border-radius: 50%;
    display: inline-block;
  }
  .goal-title {
    color: $black;
    background: #fff;
    display: inline-block;
    padding: 2px 4px;
  }
  .title-badge {
    height: 30px;
    width: 30px;
    text-align: center;
    line-height: 30px;
    background: rgba(17, 164, 76, 0.3);
    border-radius: 4px;
    color: var(--main-color);
  }
}

/*-------------------------
    ## social-area Css
---------------------------*/

.social-area {
  list-style: none;
  padding: 0;
  margin: 0;
  display: inline-flex;
  li {
    margin-right: 14px;
    a {
      color: #fff;
      border-radius: 50%;
      height: 40px;
      width: 40px;
      line-height: 40px;
      text-align: center;
      font-size: 18px;
      transition: 0.4s;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: $transition;
      &.facebook {
        background: #0066ff;
      }
      &.twitter {
        background: #21afff;
      }
      &.linkedin {
        background: #007aa0;
      }
      &.pinterest {
        background: #00ff75;
      }
      &.instagram {
        background: #7e02fb;
      }
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

/*-------------------------
    ## Common Input Css
---------------------------*/

.single-input-wrap {
  position: relative;
  .single-input {
    width: 65%;
    height: 60px;
    background: $white;
    border: 1px solid #eaeaea;
    border-radius: 50px;
    box-shadow: 0px 22px 85.85px 15.15px rgba(223, 223, 223, 0.49);
    padding: 0 40px;
    font-size: 16px;
    color: red;
    line-height: 60px;
    margin-right: 8px;
    margin-bottom: 0;
    &:active,
    &:focus {
      box-shadow: 0px 22px 85.85px 15.15px rgba(223, 223, 223, 0.49);
      outline: 0;
      border: 1px solid #eaeaea;
    }
    &::placeholder {
      color: red;
    }
  }
  .btn {
    box-shadow: 0px 22px 85.85px 15.15px rgba(223, 223, 223, 0.49);
  }
}
label {
  margin-bottom: 0.5rem;
}
.form-group {
  margin-bottom: 1rem;
}
.input-group-text {
  height: 100%;
}
/*-------------------------
    ## Preloader Css
---------------------------*/

.pre-wrap {
  position: fixed;
  content: "";
  transform: translate(-100%, -240%);
  font-size: 62px;
}

.preloader-inner {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999999999999;
  background-color: rgba(2, 48, 71, 0.8);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .cancel-preloader {
    position: absolute;
    bottom: 30px;
    right: 30px;
    a {
      background-color: $white;
      font-weight: 600;
      text-transform: capitalize;
      color: var(--main-color);
      width: 200px;
      height: 50px;
      text-align: center;
      line-height: 50px;
      border-radius: 30px;
      display: block;
      @include transition($transition);
      &:hover {
        background-color: var(--heading-color);
        color: $white;
      }
    }
  }
}

.spinner {
  margin: 120px auto;
  width: 60px;
  height: 60px;
  position: relative;
  text-align: center;
  -webkit-animation: sk-rotate 2s infinite linear;
  animation: sk-rotate 2s infinite linear;
}

.dot1,
.dot2 {
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: var(--main-color);
  border-radius: 100%;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.dot2 {
  top: auto;
  bottom: 0;
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes sk-rotate {
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

/*----------------------------------------
  ## Search Popup
----------------------------------------*/

.search-popup.active .search-form {
  visibility: visible;
  opacity: 1;
}

.search-popup .search-form {
  min-width: 600px;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transition: 0.5s ease;
  -moz-transition: 0.5s ease;
  -o-transition: 0.5s ease;
  transition: 0.5s ease;
  visibility: hidden;
  opacity: 0;
  -ms-transform: translate(-50%, -50%);
  /* IE 9 */
  -webkit-transform: translate(-50%, -50%);
  /* Chrome, Safari, Opera */
  transform: translate(-50%, -50%);
  z-index: 99999;
}

.search-popup .search-form .form-group .form-control {
  border: none;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  height: 54px;
  padding: 0 100px 0 30px;
}

.search-popup .search-form .submit-btn {
  position: absolute;
  right: 0;
  top: 0;
  border: 0;
  width: 60px;
  height: 54px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: var(--main-color);
  @include transition($transition);
  padding: 0;
  text-align: center;
  color: $white;
  &:hover {
    background-color: var(--heading-color);
  }
}

.body-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  display: block;
  background-color: rgba(0, 0, 0, 0.99);
  z-index: 99999;
  content: "";
  left: 100%;
  top: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 0.4s ease-in;
  cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVBAMAAABbObilAAAAMFBMVEVMaXH////////////////////////////////////////////////////////////6w4mEAAAAD3RSTlMAlAX+BKLcA5+b6hJ7foD4ZP1OAAAAkUlEQVR4XkWPoQ3CUBQAL4SktoKAbCUjgAKLJZ2ABYosngTJCHSD6joUI6BZgqSoB/+Shqde7sS9x3OGk81fdO+texMtRVTia+TsQtHEUJLdohJfgNNPJHyEJPZTsWLoxShqsWITazEwqePAn69Sw2TUxk1+euPis3EwaXy8RMHSZBIlRcKKnC5hRctjMf57/wJbBlAIs9k1BAAAAABJRU5ErkJggg==),
    progress;
}

/********** single-switch **********/

.single-switch {
  float: left;
  display: flex;
  .switch-title {
    margin-top: 4px;
    color: #7b7a7a;
  }
  label {
    margin-bottom: 0;
    margin: 0 5px;
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 52px;
    height: 26px;
  }
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #eaeaea;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 3px;
    bottom: 3px;
    background-color: #11a44c;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  input:checked + .slider {
    background-color: #eaeaea;
  }
  input:focus + .slider {
    box-shadow: none;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  .slider.round {
    border-radius: 34px;
  }
  .slider.round:before {
    border-radius: 50%;
  }
}

/********** auto nitification **********/

.fade-modal-nitification {
  .modal-dialog {
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
  }
  .ba-bill-pay-inner {
    border: 0;
    margin-bottom: 10px;
  }
  .modal-content {
    border-radius: 0;
  }
}

.border-radius-5 {
  border-radius: 5px !important;
}
.border-gray {
  border: 1px solid #dedede;
}
