/*
 * NOTIFICATIONS
 */

.noty_layout_mixin,
#noty_layout__top,
#noty_layout__topLeft,
#noty_layout__topCenter,
#noty_layout__topRight,
#noty_layout__bottom,
#noty_layout__bottomLeft,
#noty_layout__bottomCenter,
#noty_layout__bottomRight,
#noty_layout__center,
#noty_layout__centerLeft,
#noty_layout__centerRight {
    position: fixed;
    margin: 0;
    padding: 0;
    z-index: 9999999;
    border-radius: 4px;
    -webkit-transform: translateZ(0) scale(1, 1);
    transform: translateZ(0) scale(1, 1);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-font-smoothing: subpixel-antialiased;
    filter: blur(0);
    -webkit-filter: blur(0);
    max-width: 90%;
}

#noty_layout__top {
    top: 0;
    left: 5%;
    width: 90%;
}

#noty_layout__topLeft {
    top: 20px;
    left: 20px;
    width: 325px;
}

#noty_layout__topCenter {
    top: 5%;
    left: 50%;
    width: 325px;
    -webkit-transform: translate(-webkit-calc(-50% - .5px)) translateZ(0) scale(1, 1);
    transform: translate(calc(-50% - .5px)) translateZ(0) scale(1, 1);
}

#noty_layout__topRight {
    top: 20px;
    right: 20px;
    width: 325px;
}

#noty_layout__bottom {
    bottom: 0;
    left: 5%;
    width: 90%;
}

#noty_layout__bottomLeft {
    bottom: 20px;
    left: 20px;
    width: 325px;
}

#noty_layout__bottomCenter {
    bottom: 5%;
    left: 50%;
    width: 325px;
    -webkit-transform: translate(-webkit-calc(-50% - .5px)) translateZ(0) scale(1, 1);
    transform: translate(calc(-50% - .5px)) translateZ(0) scale(1, 1);
}

#noty_layout__bottomRight {
    bottom: 20px;
    right: 20px;
    width: 325px;
}

#noty_layout__center {
    top: 50%;
    left: 50%;
    width: 325px;
    -webkit-transform: translate(-webkit-calc(-50% - .5px), -webkit-calc(-50% - .5px)) translateZ(0) scale(1, 1);
    transform: translate(calc(-50% - .5px), calc(-50% - .5px)) translateZ(0) scale(1, 1);
}

#noty_layout__centerLeft {
    top: 50%;
    left: 20px;
    width: 325px;
    -webkit-transform: translate(0, -webkit-calc(-50% - .5px)) translateZ(0) scale(1, 1);
    transform: translate(0, calc(-50% - .5px)) translateZ(0) scale(1, 1);
}

#noty_layout__centerRight {
    top: 50%;
    right: 20px;
    width: 325px;
    -webkit-transform: translate(0, -webkit-calc(-50% - .5px)) translateZ(0) scale(1, 1);
    transform: translate(0, calc(-50% - .5px)) translateZ(0) scale(1, 1);
}

.noty_progressbar {
    display: none;
}

.noty_has_timeout.noty_has_progressbar .noty_progressbar {
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 0 0 4px 4px;
    opacity: 0.3;
    filter: alpha(opacity=10);
}

.noty_bar {
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate(0, 0) translateZ(0) scale(1, 1);
    -ms-transform: translate(0, 0) scale(1, 1);
    transform: translate(0, 0) scale(1, 1);
    -webkit-font-smoothing: subpixel-antialiased;
    overflow: hidden;
}

.noty_effects_open {
    opacity: 0;
    -webkit-transform: translate(50%);
    -ms-transform: translate(50%);
    transform: translate(50%);
    -webkit-animation: noty_anim_in 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    animation: noty_anim_in 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

.noty_effects_close {
    -webkit-animation: noty_anim_out 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    animation: noty_anim_out 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

.noty_fix_effects_height {
    -webkit-animation: noty_anim_height 75ms ease-out;
    animation: noty_anim_height 75ms ease-out;
}

.noty_close_with_click {
    cursor: pointer;
}

.noty_close_button {
    position: absolute;
    top: 2px;
    right: 2px;
    font-weight: bold;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 2px;
    cursor: pointer;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
}

.noty_close_button:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.noty_modal {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #000;
    z-index: 10000;
    opacity: .3;
    left: 0;
    top: 0;
}

.noty_modal.noty_modal_open {
    opacity: 0;
    -webkit-animation: noty_modal_in .3s ease-out;
    animation: noty_modal_in .3s ease-out;
}

.noty_modal.noty_modal_close {
    -webkit-animation: noty_modal_out .3s ease-out;
    animation: noty_modal_out .3s ease-out;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

@-webkit-keyframes noty_modal_in {
    100% {
        opacity: .3;
    }
}

@keyframes noty_modal_in {
    100% {
        opacity: .3;
    }
}

@-webkit-keyframes noty_modal_out {
    100% {
        opacity: 0;
    }
}

@keyframes noty_modal_out {
    100% {
        opacity: 0;
    }
}

@keyframes noty_modal_out {
    100% {
        opacity: 0;
    }
}

@-webkit-keyframes noty_anim_in {
    100% {
        -webkit-transform: translate(0);
        transform: translate(0);
        opacity: 1;
    }
}

@keyframes noty_anim_in {
    100% {
        -webkit-transform: translate(0);
        transform: translate(0);
        opacity: 1;
    }
}

@-webkit-keyframes noty_anim_out {
    100% {
        -webkit-transform: translate(50%);
        transform: translate(50%);
        opacity: 0;
    }
}

@keyframes noty_anim_out {
    100% {
        -webkit-transform: translate(50%);
        transform: translate(50%);
        opacity: 0;
    }
}

@-webkit-keyframes noty_anim_height {
    100% {
        height: 0;
    }
}

@keyframes noty_anim_height {
    100% {
        height: 0;
    }
}

.noty_bar {
    margin: 4px 0;
    overflow: hidden;
    position: relative;
    border: 1px solid transparent;
    border-radius: .25rem;
}

.noty_bar .noty_body {
    font-weight: 600;
    padding: 1.07rem 1.25rem;
}

.noty_bar .noty_buttons {
    padding: 1.07rem;
}

.noty_bar .noty_close_button {
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 1;
    color: #fff;
    filter: alpha(opacity=20);
    opacity: .5;
    background: transparent;
}

.noty_bar .noty_close_button:hover {
    background: transparent;
    text-decoration: none;
    cursor: pointer;
    filter: alpha(opacity=50);
    opacity: .75;
}

.noty_type__alert,
.noty_type__notification {
    background-color: rgba(44, 48, 77, 0.8);
    color: #fff;
}

.noty_type__notification.noty_has_timeout.noty_has_progressbar .noty_progressbar {
    background-color: #e76c90;
    opacity: 0.5;
}

.noty_type__warning {
    background-color: #f0ad4e;
    color: #fff;
    border-color: #f0ad4e;
}

.noty_type__error {
    background-color: #fe195e;
    color: #fff;
    border-color: #fe195e;
}

.noty_type__info,
.noty_type__information {
    background-color: #5bc0de;
    color: #fff;
    border-color: #5bc0de;
}

.noty_type__success {
    background-color: #5cb85c;
    color: #fff;
    border-color: #5cb85c;
}