/*---------------------------------------------------
	banner style
----------------------------------------------------*/
.balance-area-bg {
	position: relative;
	.edit-title {
		position: absolute;
		right: 10px;
		top: 10px;
		color: #fff;
	}
}
.balance-title {
	padding: 18px 0 12px 0;
	h6 {
		font-weight: 500;
		line-height: 28px;
		color: #15131C;
	}
}
.ba-balance-inner {
	background: #fff;
	position: relative;
	border-radius: 50px;
	padding: 11px 25px 0 60px;
	display: block;
	overflow: hidden;
	height: 60px;
	.icon {
		position: absolute;
		left: 0;
		top: 0;
		background: var(--main-color);
		border-radius: 50%;
		height: 60px;
		width: 60px;
		line-height: 60px;
		display: inline-block;
		text-align: center;
	}
	h5 {
		font-size: 15px;
		margin-bottom: 0;
	}
	.title {
		font-size: 16px;
		font-weight: 700;
		margin-bottom: 3px;
	}
	.amount {
		color: var(--main-color);
		font-size: 14px;
		font-weight: 700;
	}
}