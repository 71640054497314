/*---------------------------------------------------
    Header style
----------------------------------------------------*/
.page-title {
  background: #e7fff0;
  padding: 12px 0;
  overflow: hidden;
  font-weight: 500;
  color: var(--heading-color);
}
.ba-page-name {
  h3 {
    color: #fff;
    background: #ff396f;
    padding: 17px;
    margin-bottom: 0;
  }
}

.header-top {
  p {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
  }
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 5px;
    li {
      list-style: none;
      display: inline-block;
    }
  }
}
.dark header {
  // height: 90px;
  background: #181818;
}

.header-area {
  padding: 5px 0 10px 0;
  position: fixed;
  width: 100%;
  z-index: 9;
  top: 0;
  border-radius: 20px 20px 10px 10px;
  .menu-bar {
    margin-top: 5px;
    color: $white;
    cursor: pointer;
  }
  .logo {
    margin-top: 5px;
    display: inline-block;
  }
  .menu-back-page {
    margin-top: 6px;
    color: $white;
    font-size: 20px;
    cursor: pointer;
  }
  .page-name {
    margin-top: 5px;
    color: $white;
    font-weight: 700;
    font-size: 16px;
  }
  .header-search {
    margin-top: 5px;
    color: $white;
    cursor: pointer;
  }
}
.header-area .ba-nose-img {
  position: absolute;
  top: -8px;
  left: 50%;
  transform: translateX(-50%);
}
.header-right {
  display: inline-flex;
  margin: 0;
  padding: 0;
  li {
    display: inline-flex;
    align-self: center;
    list-style: none;
    margin-left: 10px;
    a {
      font-size: 18px;
      font-weight: 300;
      color: $white;
      position: relative;
      .badge {
        width: 15px;
        height: 15px;
        line-height: 11px;
        font-size: 9px;
        font-weight: 300;
        text-align: center;
        position: absolute;
        right: -5px;
        top: 0;
        border-radius: 50%;
        background: #ec4760;
      }
    }
    .header-user {
      margin-left: 8px;
      img {
        border-radius: 50%;
        height: 25px;
        width: 25px;
        border-radius: 50%;
      }
    }
  }
}

/************ navbar style *************/
.ba-navbar {
  width: 370px;
  position: fixed;
  left: -370px;
  top: 0;
  transition: 0.5s;
  z-index: 9;
  background: #f7f9fa;
  height: 100vh;
  overflow-y: scroll;
  &.active {
    left: 0;
  }
  .ba-navbar-user {
    padding: 35px 20px;
    position: relative;
    margin-bottom: 10px;
    .thumb {
      margin-bottom: 10px;
      img {
        border: 5px solid var(--color-main);
      }
    }
    .menu-close {
      position: absolute;
      right: 20px;
      top: 30px;
      transform: translateY(-50%);
      display: inline-block;
      height: 30px;
      width: 30px;
      line-height: 30px;
      text-align: center;
      border-radius: 50%;
      color: $color-main;
      cursor: pointer;
      font-size: 30px;
    }
    p {
      margin-bottom: 0;
    }
  }
  .ba-balance-inner {
    margin: 0 20px;
  }
  .ba-add-balance-title {
    margin: 0 20px;
    h5 {
      color: $black;
    }
    &.style-two {
      margin-top: 20px;
    }
  }
  .ba-main-menu {
    margin-top: 20px;
    padding: 20px;
    margin-bottom: 50px;
    h5 {
      margin-bottom: 20px;
    }
    ul {
      margin: 0;
      padding: 0;
      margin-bottom: 38px;
      li {
        display: block;
        list-style: none;
        a {
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: relative;
          transition: $transition;
          padding: 7px 13px;
          border-radius: 50px;
          &.active,
          &:hover {
            color: #fff;
            background: var(--main-color);
            &:after {
              color: #fff;
            }
          }
        }
      }
    }
    .btn {
      width: 100%;
      font-weight: 400;
    }
  }
  &.ba-navbar-show {
    left: 0;
    transition: 0.5s;
  }
}

/*---------------------------------------------------
  ## Search Popup
---------------------------------------------------*/
.search-popup.active .search-form {
  visibility: visible;
  opacity: 1;
}
.search-popup .search-form {
  min-width: 92%;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transition: 0.5s ease;
  -moz-transition: 0.5s ease;
  -o-transition: 0.5s ease;
  transition: 0.5s ease;
  visibility: hidden;
  opacity: 0;
  -ms-transform: translate(-50%, -50%);
  /* IE 9 */
  -webkit-transform: translate(-50%, -50%);
  /* Chrome, Safari, Opera */
  transform: translate(-50%, -50%);
  z-index: 99999;
}
.search-popup .search-form .form-group .form-control {
  border: none;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  height: 54px;
  padding: 0 100px 0 30px;
}
.search-popup .search-form .submit-btn {
  position: absolute;
  right: 0;
  top: 0;
  border: 0;
  width: 60px;
  height: 54px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: red;
  @include transition($transition);
  padding: 0;
  text-align: center;
  color: $white;
  &:hover {
    background-color: red;
  }
}
.body-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  display: block;
  background-color: rgba(0, 0, 0, 0.54);
  z-index: 99999;
  content: "";
  left: 100%;
  top: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 0.4s ease-in;
  cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVBAMAAABbObilAAAAMFBMVEVMaXH////////////////////////////////////////////////////////////6w4mEAAAAD3RSTlMAlAX+BKLcA5+b6hJ7foD4ZP1OAAAAkUlEQVR4XkWPoQ3CUBQAL4SktoKAbCUjgAKLJZ2ABYosngTJCHSD6joUI6BZgqSoB/+Shqde7sS9x3OGk81fdO+texMtRVTia+TsQtHEUJLdohJfgNNPJHyEJPZTsWLoxShqsWITazEwqePAn69Sw2TUxk1+euPis3EwaXy8RMHSZBIlRcKKnC5hRctjMf57/wJbBlAIs9k1BAAAAABJRU5ErkJggg==),
    progress;
}
.body-overlay.active {
  visibility: visible;
  opacity: 0.8;
  left: 0;
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animated.infinite {
  animation-iteration-count: infinite;
}

@keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 15deg);
  }
  40% {
    transform: rotate3d(0, 0, 1, -10deg);
  }
  60% {
    transform: rotate3d(0, 0, 1, 5deg);
  }
  80% {
    transform: rotate3d(0, 0, 1, -5deg);
  }
  to {
    transform: rotate3d(0, 0, 1, 0deg);
  }
}

.swing {
  transform-origin: top center;
  animation-name: swing;
}

@keyframes bounce {
  from,
  20%,
  53%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }
  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -30px, 0);
  }
  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    transform: translate3d(0, -4px, 0);
  }
}

.bounce {
  animation-name: bounce;
  transform-origin: center bottom;
}

@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  to {
    transform: scale3d(1, 1, 1);
  }
}

.pulse {
  animation-name: pulse;
}

.header-right li a .badge {
  display: block;
  width: 14px;
  height: 14px;
  padding: 0;
  line-height: 14px;
  border-radius: 50%;
  box-shadow: 0 0 0 rgba(255, 255, 255, 0);
  animation: BadgePulse 1.8s infinite;
}

@-webkit-keyframes BadgePulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }
}

@keyframes BadgePulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }
}
